import * as React from "react"
import appInsights from '../../../../AppInsights';      
import New from "../../../route/new" 

 



// markup
const IndexPage = (props) => {
  return (
    <React.Fragment>
      <New props={props} />
      </React.Fragment>
  )
}

export default IndexPage
