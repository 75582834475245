import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby"
import ReactMarkdown from 'react-markdown'

const objectGraphql = graphql`
{
    allStrapiSeo(filter: {locale: {eq: "es-PE"}}) {
        edges {
          node {
            titulo
            correo
            descripcion
            logo1 {
              url
            }
            logo2 {
              url
            }
            logoR1 {
              url
            }
            logoR2 {
              url
            }
            Unete_red_talentos {
                url
            }
            meta
            telefono
            ubicacion
          }
        }
      }
  
  allStrapiMenu1S(filter: {locale: {eq: "es-PE"}}) {
    nodes {
      id
      nombre
      url
    }
  }
  allStrapiMenu2S(filter: {locale: {eq: "es-PE"}}) {
    nodes {
      nombre
      id
      url
    }
  }
}
`

console.log(objectGraphql);
class Footer extends Component {



    render() {

        return (
            <StaticQuery
                query={objectGraphql}
                render={data => (
                    <footer>
                        <div className="container-fluid fondo-esp">
                            <div className="row footer-cont">
                                <div className="col-md-5 col-lg-2">
                                    <p>
                                        <img alt="Pesquera Centinela" className="centinela-ch" src={ data.allStrapiSeo.edges[0].node.logo2[0].url} />
                                    </p>
                                </div>
                                <div className="col-md-7 col-lg-3">
                                    <h4>{data.allStrapiSeo.edges[0].node.titulo}</h4>

                                    <p>{data.allStrapiSeo.edges[0].node.descripcion}</p>
                                </div>
                                <div className="col-md-5 col-lg-3">
                                    <h4>Contáctanos</h4>
                                    <p>
                                        <strong>Oficina principal</strong>
                                        <br />
                                        <ReactMarkdown className='mb-0-p'>{data.allStrapiSeo.edges[0].node.ubicacion}</ReactMarkdown>

                                    </p>
                                    <p>
                                        <strong>Teléfonos</strong>
                                        <br />
                                        {data.allStrapiSeo.edges[0].node.telefono}
                                    </p>
                                    <p>
                                        <strong>Email</strong><br />
                                        <a href={"mailto:" + data.allStrapiSeo.edges[0].node.correo}>{data.allStrapiSeo.edges[0].node.correo}</a></p>
                                </div>

                                <div className="col-md-7 col-lg-4">
                                    <h4>Navegación</h4>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <ul className="links">
                                                {
                                                    data.allStrapiMenu2S.nodes.map((item) => {
                                                        return (
                                                            <li key={item.id}><a href={item.url}>{item.nombre}</a></li>
                                                        )
                                                    })
                                                }
                                            </ul>

                                        </div>
                                        <div className="col-md-6">
                                            <ul className="links">
                                                {
                                                    data.allStrapiMenu1S.nodes.map((item) => {
                                                        return (
                                                            <li key={item.id}><a href={item.url}>{item.nombre}</a></li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h4 className="color-b"><strong>
                                        <a className="color-b" target="_blank" href={data.allStrapiSeo.edges[0].node.Unete_red_talentos.url}  >¡Únete a nuestra red de talentos!<br />
                                        <br />
                                        
                                        </a> </strong></h4>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 text-center">
                                    <hr />
                                    <p className="color-b">Pesquera Centinela. Todos los derechos reservados 2017.</p>

                                    <p><a className="link-two" href="/es/aviso-legal" target="_blank" rel="noreferrer">Aviso legal</a></p>
                                    <a href="http://www.gruporomero.com.pe" target="_blank" rel="noreferrer"><img alt="Grupo Romero" className="gr" src={ data.allStrapiSeo.edges[0].node.logoR1[0].url} /></a></div>
                            </div>
                        </div>
                    </footer>

                )}
            />



        )
    }
}


export default Footer
